<template>
  <div class="appoint-deputy">
    <div class="appoint-deputy__date">
      <ui-date-picker
          :label="$t('date-of-replacement-from')"
          v-model="dateFrom"
      />
      <ui-date-picker
          :label="$t('date-of-replacement-to')"
          v-model="dateBy"
      />
    </div>
    <div class="appoint-deputy__search">
      <ui-input search :placeholder="$t('search')" v-model="search" />
      <div class="appoint-deputy__search-icons">
        <img @click="visibleFilter = !visibleFilter" :src="require(`@/assets/svg/search/${filterColor}-filter.svg`)" alt="">
        <ui-menu v-model="visibleSort">
          <template #activator>
            <img :src="require(`@/assets/svg/search/${sortColor}-sort.svg`)" alt="">
          </template>
          <template #body>
            <div class="sort">
              <p
                  @click="changeSort(item)"
                  v-for="(item, key) in sortData"
                  :class="['sort__item', { active: item.value === sortBy.value }]"
                  :key="key"
              >
                {{ item.text }}
                <img v-if="item.value === sortBy.value" src="@/assets/svg/red-check.svg" alt="">
              </p>
            </div>
          </template>
        </ui-menu>
      </div>
    </div>
    <search-filter
        v-if="visibleFilter"
        :disabled="isNumbersValid"
    >
      <ui-input
          v-model="searchObj.ordersFrom"
          :label="$t('number-of-requests-from')"
          :error="isNumbersValid"
          v-mask="'########'"
      />
      <ui-input
          v-model="searchObj.ordersTo"
          :label="$t('number-of-requests-to')"
          :error="isNumbersValid"
          v-mask="'########'"
      />
      <ui-select
          v-model="searchObj.region"
          :label="$t('region')"
          :options="getRegions"
      />
    </search-filter>
  </div>
</template>


<script>
import {defineComponent} from "vue";
import UiInput from "@/components/ui/UiInput.vue";
import UiMenu from "@/components/ui/UiMenu.vue";
import SearchFilter from "@/components/common/SearchFilter.vue";
import UiSelect from "@/components/ui/UiSelect.vue";
import {mapGetters} from "vuex";
import UiDatePicker from "@/components/ui/UiDatePicker.vue";

export default defineComponent({
  name: 'AppointDeputy',
  components: {UiDatePicker, UiSelect, SearchFilter, UiMenu, UiInput},
  data () {
    return {
      search: '',
      dateFrom: '',
      dateBy: '',
      visibleFilter: false,
      visibleSort: false,
      sortData: [
        { text: this.$t('by-region'), value: 'region' },
        { text: this.$t('by-number-of-requests-1'), value: 'number' }
      ],
      searchObj: {
        region: null,
        ordersFrom: '',
        ordersTo: ''
      },
      sortBy: '',
    }
  },

  methods: {
    // searchList() {
    //   clearTimeout(this.timer)
    //   this.timer = setTimeout(() => {
    //     this.updateList()
    //   }, 500)
    // },
    changeSort(val) {
      this.sortBy = val
      this.visibleSort = false
      this.updateList()
    },
  },

  computed: {
    ...mapGetters(['getRegions']),
    filterColor() {
      return this.visibleFilter ? 'red' : 'black'
    },

    sortColor() {
      return this.visibleSort ? 'red' : 'black'
    },
    isNumbersValid() {
      return (Number(this.searchObj.ordersFrom) >= Number(this.searchObj.ordersTo)) && !!this.searchObj.ordersFrom && !!this.searchObj.ordersTo
    }
  }
})
</script>

<style lang="scss" scoped>
.appoint-deputy {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  padding-left: 130px;

  @media (max-width: 992px) {
    padding-left: 0;
  }

  &__date {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    max-width: 887px;
    width: 100%;
  }

  &__search {
    display: flex;
    align-items: center;
    gap: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #fff;

    @media (max-width: 992px) {
      gap: 20px;
      margin-bottom: 20px;
    }

    @media (max-width: 768px) {
      padding: 0;
      border: none;
    }

    &-icons {
      display: flex;
      align-items: center;
      gap: 20px;

      @media (max-width: 992px) {
        gap: 10px;
      }

      img {
        cursor: pointer;
      }
    }
  }
}
::v-deep .filter__row {
  display: grid !important;
  grid-template-columns: 1fr 1fr 2fr;

  @media (max-width: 1200px) {
    display: flex !important;
    flex-direction: column;
  }
}
</style>
